<template>
  <section class="container discover">
    <div class="wrap">
      <div class="image">
        <div class="small">
        </div>

        <div class="medium">
          <div class="box">
            <img
              v-for="(image, i) in images.slice(0, 2)"
              :key="i"
              :src="image">
          </div>

          <div class="box">
            <img
              v-for="(image, i) in images.slice(2, 4)"
              :key="i"
              :src="image">
          </div>
        </div>
      </div>

      <div class="content">
        <div class="text-vector">
          <img src="@/assets/graphics/discover-our-products.svg">
        </div>

        <router-link
          id="homepageMenuLink"
          :to="{ name: 'menu' }">
          <div class="link">
            <span>VIEW OUR MENU</span>
            <div class="line"></div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      images: [
        require('@/assets/images/product-01.jpg'),
        require('@/assets/images/product-02.jpg'),
        require('@/assets/images/product-03.jpg'),
        require('@/assets/images/product-04.jpg')
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.discover {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 100vw;
    align-items: center;
    height: 620px;
  }

  .wrap {
    @media screen and (min-width: 768px) {
      margin-top: -18%;
    }

    @media screen and (min-width: 1440px) {
      margin-left: 16%;
    }
  }

  .image {
    @media screen and (min-width: 768px) {
      width: 100%;
    }

    img {
      margin: 0 7px;
      width: 175px;
      height: 175px;
      border-radius: 10px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);

      @media screen and (min-width: 768px) {
        margin: 12px 12px;
        width: 160px;
        height: 160px;
      }

      @media screen and (min-width: 1024px) {
        width: 220px;
        height: 220px;
      }
    }

    .small {
      transform: translateX(-99909vw);
      display: flex;
      width: 100003vw;
      height: 220px;
      animation: sildeImage 10000s linear infinite;
      background-repeat: repeat-x;
      background-size: auto 100%;
      background-position: 100px center;
      background-image: url('~@/assets/images/silde-dicover.png');

      @media screen and (min-width: 768px) {
        display: none;
      }

      @keyframes sildeImage {
        0% {
          transform: translateX(-99909vw);
        }

        100% {
          transform: translateX(0vw);
        }
      }
    }

    .medium {
      display: none;

      .box:nth-child(even) {
        margin-left: 28px;
      }

      @media screen and (min-width: 768px) {
        display: block;
      }
    }
  }

  .content {
    position: relative;
    left: calc(20vw - 30px);
    margin-top: -40px;
    width: fit-content;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -55%;
      margin-left: -68px;
      left: calc(50% + 150px);
      width: 80%;
    }

    @media screen and (min-width: 1024px) {
       left: calc(50% + 200px);
    }

    .text-vector {
      display: flex;

      img {
        object-fit: fill;
        width: 80vw;
        height: 100%;

        @media screen and (min-width: 768px) {
          width: 100%;
          height: 200px;
        }
      }
    }

    a {
      .link {
        margin-top: 18px;
        width: fit-content;

        @media screen and (min-width: 768px) {
          // transform: translateY(42px);
          margin-top: 16px;
          margin-left: 90px;
        }

        span {
          font-family: 'DB Ozone X Bd';
          font-size: 18px;
        }

        .line {
          margin-top: -4px;
          width: 50%;
          height: 2px;
          background-color: $color-main;
          transition: 800ms $ease;
        }
      }

      .link:hover {
        .line {
          width: 100%;
        }
      }
    }
  }
}
</style>
